import styles from "./Footer.module.css";
import Logo from "../../assets/images/LWSLogo.png";

const Footer = () => {
  const dateYear = new Date().getFullYear();

  return (
    <div className={styles.section}>
      <div className={styles["footer-logo"]}>
        <img className={styles["small-logo"]} src={Logo} alt="LWS-logo"></img>
      </div>
      {dateYear == "2023" ? (
        <p>&#169; 2023 Leading Way Solutions, LLC</p>
      ) : (
        <p> &#169; 2023 - {dateYear} Leading Way Solutions, LLC</p>
      )}
      <div className={styles.linkContainer}>
        <a href="https://leadingwaysolutions.com" target="_blank" className={styles.link}>www.leadingwaysolutions.com</a>
      </div>
    </div>
  );
};

export default Footer;