import { Outlet } from "react-router-dom";
import TitleBar from "../../components/titleBar/TitleBar";
import Footer from "../../components/footer/Footer";
import styles from "./RootLayout.module.css";
const RootLayout = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <TitleBar />
      </div>
        <Outlet />
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};
export default RootLayout;
