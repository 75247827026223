import styles from  "./Privacy.module.css";
const Privacy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Privacy Policy</h1>
      <div> 
        <p className={styles.info}>
          Where Did I Put My Stuff is owned and managed by Leading Way Solutions, LLC. We will never share personal information with third parties.
          Information is only collected for use in the application. Location will never be needed for this application. Photos
          collected will never be used or shared.
        </p>
      </div>
    </div>
  );
};
export default Privacy;
