import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./screens/home/Home";
import Privacy from "./screens/privacy/Privacy";
import RootLayout from "./screens/rootLayout/RootLayout";
import ErrorPage from "./screens/errorPage/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      { path: "/privacy", element: <Privacy /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router}/>;
}

export default App;
