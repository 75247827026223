import { Link, Navigate } from "react-router-dom";
import styles from "./Home.module.css";
const Home = () => {
  return (
    <div className={styles.container}>
        <h1 className={styles.header}>Where Did I Put My Stuff</h1>
        <div>
          <p className={styles.info}>
          Welcome to Where Did I Put My Stuff! This app, owned by Leading Way Solutions, LLC, is currently in development and will be available soon. 
          It is an app for inventory management to manage your household items.
          Where Did I Put My Stuff can also be used by an organization or business to keep track of what they have in storage or inventory, so it can be found quickly! 
          This app will be available soon for both IOS and Android devices. Contact us for more information!
          </p>
        </div>
      <div className={styles.privacy}>
          <Link to="/privacy" className={styles.link}>View Our Privacy Policy</Link>
      </div> 
      <h1 className={styles.header}>Contact Us For More Information!</h1>
      <div className={styles.contactLink}>
        <a href="mailto:mystuff@leadingwaysolutions.com" className={styles.contactInfo}>
          mystuff@leadingwaysolutions.com</a>
      </div>
      <div className={styles.contactLink}>
        <a href="tel:7179648929" className={styles.contactInfo}>717-964-8929</a>
      </div>
    </div>
  );
};
export default Home;
