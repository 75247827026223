import { useRouteError } from "react-router-dom";
import "./ErrorPage.css";
const ErrorPage = () => {
  const error = useRouteError();
  return (
    <div>
      <h1>Oops!</h1>
      <p>An unexpected error has occurred.</p>
      <p>{error.statusText || error.message}</p>
    </div>
  );
};
export default ErrorPage;
