import { Link } from "react-router-dom";
import styles from"./TitleBar.module.css";
import logo from "../../assets/images/WMSBrand.webp";
const TitleBar = () => {
  return (
    <div className={styles.container}> 
      <Link to="/">
      <img src={logo} className={styles.logo} alt="WMS Logo" />
      </Link>
    </div>
  );
};
export default TitleBar;
